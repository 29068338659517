<template>
  <div>
    <page-title :title="`Quotes`" />
    <table class="table  table-striped bg-white" id="quotes-table">
      <thead>
        <tr>
          <th>#</th>
          <th>Email</th>
          <th>Tenure</th>
          <th>Amount</th>
          <th>Charge</th>
          <th>Type</th>
          <th>Requested On</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(quote, i) in quotes" :key="`quote-${i}`">
          <td>{{ i + 1 }}</td>
          <td>{{ quote.email }}</td>
          <td>{{ quote.tenure }}</td>
          <td>{{ $number.format(quote.amount) }}</td>
          <td>{{ $number.format(quote.charge) }}</td>
          <td>{{ quote.type }}</td>
          <td>{{ quote.created_at }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import $ from 'jquery'

export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      quotes: [],
    };
  },

  methods: {
    fetch() {
      this.$loader.start()

      this.$axios
        .get("/api/v1/dashboard/quotes")
        .then((response) => {
          this.quotes = response.data;
          setTimeout(() => {
            this.$loader.stop()
            $('table').DataTable()
          }, 1000)
        })
        .catch((error) => {
          this.$http(error.response);
          this.$loader.stop()
        });
    },
  },
};
</script>
